//Arquivo responsável por criar o checkbox de seleção de mensagens.
import React, { useContext, useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { ForwardMessageContext } from "../../context/ForwarMessage/ForwardMessageContext";

const SelectMessageCheckbox = ({ message }) => {
    const [isChecked, setIsChecked] = useState(false);
    const {
        showSelectMessageCheckbox,
        setSelectedMessages,
        selectedMessages,
        setShowSelectMessageCheckbox // Controla o estado de exibição do encaminhamento
    } = useContext(ForwardMessageContext);

    // Sincroniza o estado local com o contexto
    useEffect(() => {
        const isMessageSelected = selectedMessages.some((m) => m.id === message.id);
        setIsChecked(isMessageSelected);
    }, [selectedMessages, message]);

    // Adiciona ou remove a mensagem selecionada
    const handleSelectMessage = (e) => {
        const updatedList = e.target.checked
            ? [...selectedMessages, message]
            : selectedMessages.filter((m) => m.id !== message.id);

        setSelectedMessages(updatedList);
    };

    // Limpa a seleção e interrompe o encaminhamento ao pressionar ESC
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                setSelectedMessages([]); // Limpa as mensagens selecionadas
                setShowSelectMessageCheckbox(false); // Fecha o estado de encaminhamento
            }
        };

        // Adiciona um listener de teclado para capturar a tecla ESC
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            // Remove o listener de teclado ao desmontar o componente
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [setSelectedMessages, setShowSelectMessageCheckbox]);

    if (!showSelectMessageCheckbox) return null;

    return (
        <Checkbox
            color="primary"
            checked={isChecked}
            onChange={handleSelectMessage}
        />
    );
};

export default SelectMessageCheckbox;